form{
  margin: 30px 0px;
}

.viewer{
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  overflow-y: auto;
  margin-bottom: 10px;
}

span{
  margin-top: 3px;
}